<template>
  <div class="login">
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      class="form"
    >
      <!-- 账号 -->
    <p class="pp">浙江新聚投放后台管理系统</p>
      <el-form-item prop="account">
        <el-input
          type="text"
          placeholder="请输入账号"
          prefix-icon="el-icon-user-solid"
          v-model="ruleForm.account"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <!-- 密码 -->
      <el-form-item prop="pw">
        <el-input
          type="text"
          placeholder="请输入密码"
          prefix-icon="el-icon-lock"
          v-model="ruleForm.pw"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { login } from "@/api/http";
export default {
  name: "login",
  data() {
    return {
      ruleForm: {
        account: "",
        pw: "",
      },
      rules: {
        account: [
          { required: true, message: "不能为空", trigger: "blur" },
          {
            pattern: /^\w{3,8}$/,
            message: "必须是3-5位的字符",
            trigger: "blur",
          },
        ],
        pw: [
          { required: true, message: "不能为空", trigger: "blur" },
          {
            pattern: /^\d{2,6}$/,
            message: "必须是2-6位的纯数字",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //表单数据有效时的处理
    async handleValid() {
      try {
        //当数据都有效时，调用后端接口，进行登录验证
        let res = await login(this.ruleForm);
        let { code, token ,role} = res.data;
        if (code === 0) {
          // console.log('login-role',role);
          //登录成功
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("role", role);
          this.$message.success("登录成功");
          // 登录的分类讨论：
          // 1. 首次登录，没有参数redirectUrl,  登录成功后进入首页
          // 2. 退出登录后再次登录，有参数redirectUrl,登录成功后进入上一次退出登录的那个路由
          let { redirectUrl } = this.$route.query;
          if (redirectUrl) {
            this.$router.replace(redirectUrl);
          } else {
            this.$router.replace("/info/info");
          }
        } else {
          //登录失败
          this.$message.error("登录失败");
          this.$refs[formName].resetFields();
        }
      } catch (err) {
        // console.log('login-92-error',err);
        if (err.code === 'ERR_NETWORK'){
          this.$message.error("网络错误,请重试");
        }
      }
    },
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid, fields) => {
        if (valid) {
          this.handleValid();
        } else {
          //当有字段数据无效时，阻止提交
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.login {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #2d3a4b;
  text-align: center;
  line-height: 100px;
  .pp{
    color: #fff;
    font-size: 30px;
  }
  .form {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 500px;
    // border: 2px solid #999999;
    border-radius: 30px;
  }
}
</style>
